import React, { useState } from 'react';
import { Container, Form, Button, Image } from 'react-bootstrap';

import PlaidPage from '../plaidComponent/plaidPage';

// Import snowflakeAPI object
import { SnowFlakeAPI } from '../../api/snowflakeAPI';

// Importing bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

// Importing custom css
import './signin.css';

// Importing logo
import logo from "../../assets/images/logo.png";

//SweetAlert
import Swal from 'sweetalert2';

//Phone Input
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function SignIn() {

    const [nextstep, setNextStep] = useState(false);

    const [apiKey, setapiKey] = useState('');
    const [clientID, setClientID] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [formData, setFormData] = useState({
        countryCode: 1,
        phoneNumber: '',
    });

    const handleSubmit = (event) => {

        event.preventDefault();

        console.log(phoneNumber.length);

        if (phoneNumber.length !== 11) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Phone Number',
                text: 'Please enter a valid 10 digit phone number!',
            });
            return;
        }

        //API call to SnowFlakeAPI
        SnowFlakeAPI.findUser(phoneNumber).then((response) => {

            const { apiKey, clientID, firstName, lastName } = response.data;

            setapiKey(apiKey);
            setClientID(clientID);
            setName(firstName + " " + lastName);

            //console.log(response.data);

            //Phone Number has been recorded
            setNextStep(true);

        }).catch((error) => {
            console.log(error);
        });


    };

    /** User has entered their phone number */
    if (nextstep) {

        return (
            <PlaidPage apiKey={apiKey} clientID={clientID} name={name}/>
        );
    }
    else {
        return (
                <Container className='container'>
                    <div className="main">
                        <Image src={logo} className='dfa_logo' alt="Debt Freedom Attorneys Logo" />
                        {/*<h4>Payment Support Portal</h4>*/}
                        <Form className='form' onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicText">
                                <Form.Label className="labelText">Please enter the phone number associated with your account to begin securely linking your bank account.</Form.Label>
                                <PhoneInput
                                    country={'us'}
                                    onlyCountries={['us']}
                                    countryCodeEditable={false}
                                    placeholder="+1 (000) 000-0000"
                                    value={phoneNumber}
                                    inputStyle={{width: '100%'}}
                                    onChange={phone => setPhoneNumber(phone)}
                                />
                                {/*<Form.Label className="labelText">Last name</Form.Label>
                                <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} placeholder="Enter your last name" />
                                <Form.Label className="labelText">Birthdate</Form.Label>
                                <Form.Control type="date" name="birthDate" value={formData.birthDate} onChange={handleInputChange} placeholder="Enter your birthday" min="1980-01-01"/>*/}
                            </Form.Group>
                            <Button variant="dark" className="button" type="submit">
                                Continue
                            </Button>
                        </Form>
                    </div>
                </Container>
        );
    }
}

export default SignIn;