import { api } from "./config/axiosConfig"

export const SnowFlakeAPI = {
  findUser: async function (phoneNumber) {

    const response = await api.request({
        url: `users/findUser`,
        method: "POST",
        data: {
            phoneNumber: phoneNumber
        }
    })
    
    // returning the product returned by the API
    return response
  },
}